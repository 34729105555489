<template>
  <div class="flex flex-col w-full pt-20 h-screen">
    <CheckoutHeader />

    <main class="flex-1">
      <slot />
    </main>

    <CheckoutFooter />
  </div>
</template>
